import { useInfiniteHits } from 'react-instantsearch';
import { BuildFlowCard, Card } from '@components';
import { LOCALE_DICT, EMPTY_PRODUCT, EMPTY_VARIANT, PRODUCT_TYPES, LOCALE_CODES } from '@constants';
import { NormalizedProduct } from '@ts/product';
import { CardProps } from '@ts/index';
import { normalizeProductTitle } from '@utils/normalizers';

type HitProps = {
	locale?: string;
	hit: ReturnType<typeof useInfiniteHits>['hits'][number];
	position: number;
	type?: 'buildflow' | 'all-tops' | 'search' | 'bf-all-tops';
};

type HitFields = {
	[key: string]: string;
};

const Hit = ({ locale = LOCALE_CODES['US'], hit, position, type = 'all-tops' }: HitProps) => {
	const isSearch = type === 'search';
	let price = hit.price;
	let compareAtPrice = hit.compare_at_price;
	if (locale !== LOCALE_CODES.US) {
		const currencyCode = LOCALE_DICT[locale].currencyCode.toLowerCase();
		const field = `market_pricing_${currencyCode}`;
		price = (hit[`${field}`] as HitFields)?.price || hit.price;
		compareAtPrice = (hit[`${field}`] as HitFields)?.compare_at_price || hit.compare_at_price;
	}

	const productData = {
		...EMPTY_PRODUCT,
		...{
			tags: hit.tags,
			type: hit.product_type,
			id: hit.id,
			handle: hit.handle,
			name: normalizeProductTitle(hit.title, hit.product_type),
			variants: [
				{
					...EMPTY_VARIANT,
					...{
						id: `gid://shopify/ProductVariant/${hit.objectID}`, //! must be a Shopify GID !
						sku: hit.sku,
						handle: hit.handle,
						type: hit.product_type,
						name: normalizeProductTitle(hit.title, hit.product_type),
						option: hit.product_type === PRODUCT_TYPES.GIFT_CARD ? `${hit.option1}/${hit.option2}` : hit.option1,
						availableForSale: true,
						image: {
							url: hit.image,
						},
						price: {
							amount: price,
							currencyCode: LOCALE_DICT[locale].currencyCode,
						},
						compareAtPrice: compareAtPrice && {
							amount: compareAtPrice,
							currencyCode: LOCALE_DICT[locale].currencyCode,
						},
						product: {
							id: hit.id,
							handle: hit.handle,
							name: normalizeProductTitle(hit.title, hit.product_type),
							type: hit.product_type,
							tags: [],
						},
					},
				},
			],
		},
	} as NormalizedProduct;

	let cardProps: Omit<CardProps, 'children'> = {
		product: productData,
		variant: productData.variants[0],
		showTags: true,
		showCollectionLozenge: true,
		showDescription: true,
		secondaryAction: 'favorite',
		compact: true,
		searchPosition: position,
		dataTags: {
			button: { [isSearch ? 'data-add-to-cart-from-search' : 'data-add-to-cart-from-plp']: true },
			zoom: {},
			favorite: { [isSearch ? 'data-add-favorite-from-search' : 'data-add-favorite-from-plp']: true },
		},
	};

	if (productData.type === PRODUCT_TYPES.ACCESSORY) {
		cardProps = { ...cardProps, aspectRatio: '2/1' };
	}

	if (productData.type.includes(PRODUCT_TYPES.BASE_FRAME)) {
		cardProps = { ...cardProps, aspectRatio: '1/1', primaryAction: 'view' };
	}

	// NOTE: Fixes aspect ratio for Search Cards
	if (type === 'all-tops' || isSearch) cardProps.aspectRatio = '4/3';

	return type == 'buildflow' || type === 'bf-all-tops' ? (
		<BuildFlowCard top={cardProps.variant} collectionHandle={cardProps.variant.collection} />
	) : (
		<Card {...cardProps} pageType={type} />
	);
};
export default Hit;
