import { useRouter } from 'next/router';
import cn from 'classnames';
import { CollectionLozenge } from '@ts/product';
import { BADGE_TYPES, BASE_FRAME_AGE_RANGES, BASE_FRAME_SIZING_OPTIONS, PRODUCT_TYPES } from '@constants';
import { isFalsy } from '@utils/objects';
import { Img, Lozenge } from '@components';
import { capitalizeEachWord } from '@utils/strings';
import { CardProps } from '@ts/index';
import styles from './Tags.module.scss';

const YELLOW_TAGS = ['leaving-soon', 'limited'];
const BLUE_TAGS = ['badge-best', 'fan-favorite', 'back-in-stock'];
const GREEN_TAGS = ['new'];
const PREMIUM_TAGS = ['premium'];
const RELEVANT_TAGS = [...YELLOW_TAGS, ...BLUE_TAGS, ...GREEN_TAGS, ...PREMIUM_TAGS];

type TagProps = Required<Pick<CardProps, 'compact' | 'product'>> & {
	containerType: 'vertical' | 'horizontal' | 'line-item' | 'build-flow';
	lozengeData: CollectionLozenge;
	customTags?: (typeof BADGE_TYPES.TOP_RATED)[];
	/**
	 * Default parameter set on Card
	 */
	showCollectionLozenge: boolean;
	className?: string;
};

const Tags = ({ containerType, compact, product, lozengeData, customTags, showCollectionLozenge, className }: TagProps) => {
	const router = useRouter();
	let baseFrameSize = undefined;

	const { demo } = router.query as { demo: string; base: string };
	const foundTags = product.tags.filter(t => RELEVANT_TAGS.includes(t));
	const isMetalBaseFrame = product.handle.includes('mixed-material') || product.tags.includes('mixed_material');

	if (product.type.includes(PRODUCT_TYPES.BASE_FRAME)) {
		baseFrameSize =
			demo === 'kids'
				? BASE_FRAME_AGE_RANGES[product.name.split(' ')[1]]
				: BASE_FRAME_SIZING_OPTIONS[product.name.split(' ')[1]];
	}

	const findPriorityTag = (productTags: string[], colorTags: string[], priorityTag?: string) => {
		const backInStockIndex = productTags.indexOf(priorityTag);
		if (backInStockIndex !== -1) {
			return priorityTag;
		}
		return colorTags.find(tag => productTags.includes(tag));
	};

	let yellow = foundTags.find(v => YELLOW_TAGS.includes(v));
	const blue = findPriorityTag(foundTags, BLUE_TAGS, BADGE_TYPES.BACK_IN_STOCK.name);
	const green = foundTags.find(v => GREEN_TAGS.includes(v));
	const premiumTag = foundTags.find(v => PREMIUM_TAGS.includes(v));
	const orange = BADGE_TYPES.METAL;

	const blueTag = BADGE_TYPES.BACK_IN_STOCK.name === blue ? BADGE_TYPES.BACK_IN_STOCK : BADGE_TYPES.BEST_SELLER;

	const tagsClasses = cn(styles.tags, className, {
		[styles.tagsCompact]: compact,
		[styles.tagsHorizontal]: containerType === 'horizontal',
	});

	if (yellow && yellow == 'limited') {
		yellow = 'limited edition';
	}

	return (
		<div className={tagsClasses}>
			{showCollectionLozenge && lozengeData && !isFalsy(lozengeData) && (
				<Lozenge
					backgroundColor={lozengeData.lozengeColor}
					color={lozengeData.lozengeTextColor}
					shape={'pill'}
					url={`/top-frames/${lozengeData.lozengeHandle}`}
				>
					{lozengeData.lozengeImage ? (
						<Img src={lozengeData.lozengeImage.url} alt={lozengeData.lozengeImage.altText} sizes='6.4rem' />
					) : (
						<span data-emoji='true'>{lozengeData.lozengeEmoji}</span>
					)}
					{lozengeData.title}
				</Lozenge>
			)}
			{baseFrameSize && (
				<Lozenge {...BADGE_TYPES.BASE_FRAME_SIZE} shape='square'>
					{capitalizeEachWord(baseFrameSize)}
				</Lozenge>
			)}
			{green && (
				<Lozenge {...BADGE_TYPES.NEW} shape='square'>
					{capitalizeEachWord(BADGE_TYPES.NEW.displayText)}
				</Lozenge>
			)}
			{blue && (
				<Lozenge {...blueTag} shape='square'>
					{capitalizeEachWord(blueTag.displayText)}
				</Lozenge>
			)}
			{isMetalBaseFrame && (
				<Lozenge {...orange} shape='square'>
					{capitalizeEachWord(orange.displayText)}
				</Lozenge>
			)}
			{yellow && (
				<Lozenge {...BADGE_TYPES.LIMITED} shape='square'>
					{capitalizeEachWord(yellow)}
				</Lozenge>
			)}
			{premiumTag && (
				<Lozenge {...BADGE_TYPES.PREMIUM} shape='square'>
					{capitalizeEachWord(BADGE_TYPES.PREMIUM.displayText)}
				</Lozenge>
			)}
			{customTags &&
				customTags.map((tag, index) => (
					<Lozenge key={`${index}-${tag.name}`} {...tag} shape='square'>
						{capitalizeEachWord(tag.displayText)}
					</Lozenge>
				))}
		</div>
	);
};

export default Tags;
